





















































































































































































import { createFirstReport } from "@/api/reportsService";
import RateLesson from "@/components/lessons/RateLesson.vue";
import MainGoals from "@/components/reports/forms/MainGoalsForm.vue";
import SixWeekPlan from "@/components/reports/forms/WeeksPlanForm.vue";
import handleError from "@/helpers/errors";
import Lesson from "@/interfaces/Lesson";
import { useNotifier } from "@/providers/notifier";
import { userModule } from "@/store/modules/user";
import { defineComponent, PropType, reactive, ref, watchEffect } from "@vue/composition-api";
import Vue from "vue";

export default defineComponent({
  name: "FirstLessonReportForm",
  components: {
    MainGoals,
    SixWeekPlan,
    RateLesson,
  },
  props: {
    lesson: {
      type: Object as PropType<Lesson>,
      required: true,
    },
    studentName: {
      type: String as PropType<string>,
      required: true,
    },
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props, {
    emit,
    refs,
  }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });
    const notify = useNotifier();
    const vm = reactive({
      loading: false,
      goals: {
        longTermGoal1: "",
        longTermGoal2: "",
        longTermGoal3: "",
      },
      mainGoalsValid: false,
      reportForm: {
        customerUID: props.lesson.customerUID,
        improvementPlan: "",
        lessonID: props.lesson.ID,
        name: props.studentName,
        negativeTopics: "",
        personalComment: "",
        positiveTopics: "",
        projectID: props.lesson.projectID,
        sellerUID: props.lesson.sellerUID || "",
        studiedTopics: "",
      },
      sixWeeksValid: false,
      submitted: false,
      weeks: {
        week1: "",
        week2: "",
        week3: "",
        week4: "",
        week5: "",
        week6: "",
      },
    });
    const fullName = `${userModule.state.profile.firstName} ${userModule.state.profile.lastName}`;
    const validForm = ref(false);

    function closeForm() {
      _value.value = false;
      emit("success");
    }

    const formRules = {
      required: (v: string) => !!v || "Obligatorisk felt",
      name: {
        validLength: (v: string) => v.length >= 2 || "Navnet må være minst 2 tegn langt",
      },
    };
    const submitReport = async() => {
      vm.loading = true;
      vm.submitted = true;
      (refs.form as Vue & { validate: () => boolean; }).validate();
      if (validForm.value && vm.sixWeeksValid && vm.mainGoalsValid) {
        try {
          await createFirstReport({ ...vm.reportForm, ...vm.weeks, ...vm.goals });
          emit("submitted");
          closeForm();
        }
        catch (e) {
          notify({
            title: "Klarte ikke sende Rapporten 🤯",
            message: "Kontakt support hvis problemet vedvarer.",
            type: "error",
          });
          handleError(e);
        }
      }
      else {
        notify({
          title: "Rapporten er mangelfull 📋",
          message: "Fyll ut alle feltene nøye! Det er viktig for å gi et godt inntrykk. 😃",
          type: "error",
        });
      }
      vm.loading = false;
    };
    return {
      _value,
      closeForm,
      formRules,
      fullName,
      submitReport,
      userModule,
      validForm,
      vm,
    };
  },
});















































































































import { getAllReportsForUser } from "@/api/reportsService";
import FirstLessonReportForm from "@/components/reports/forms/FirstLessonReportForm.vue";
import PeriodicalReportForm from "@/components/reports/forms/PeriodicalReportForm.vue";
import RegularReportForm from "@/components/reports/forms/RegularReportForm.vue";
import ReportList from "@/components/reports/views/ReportList.vue";
import handleError from "@/helpers/errors";
import Report, { RetrieveReportParams } from "@/interfaces/Report";
import Moment from "@/services/moment";
import { defineComponent, onMounted, reactive } from "@vue/composition-api";
import { getFirstLesson, getLessonsWithoutReport } from "@/api/lessonService";
import { userModule } from "@/store/modules/user";
import Lesson from "@/interfaces/Lesson";
import useAsyncData from "@/hooks/useAsyncData";

export default defineComponent({
  name: "SellerReports",
  components: {
    ReportList,
    FirstLessonReportForm,
    PeriodicalReportForm,
    RegularReportForm,
  },
  setup: () => {
    const vm = reactive({
      lesson: {} as Lesson,
      loading: false,
      previousReport: {} as Report,
      refresh: false,
      reportFormVisible: false,
      reports: [] as RetrieveReportParams[],
      reportType: "",
      studentName: "",
      tab: null,
    });

    const {
      isLoading,
      data: missingReportlessons,
      refresh,
    } = useAsyncData(
      () => getLessonsWithoutReport(userModule.state.userId),
    );
    onMounted(async() => {
      try {
        vm.reports = await getAllReportsForUser(userModule.state.userId, true);
      }
      catch (e) {
        handleError(e);
      }
    });
    const name = (lesson: Lesson): string => lesson.project && lesson.project.studentName ? lesson.project.studentName : "";
    const readableDate = (lesson: Lesson): string => `${Moment.unix(lesson.startTime).format("LLLL")} – ${Moment.unix(lesson.endTime).format("HH:mm")}`;
    const refreshAll = async() => {
      vm.refresh = true;
      vm.reports = await getAllReportsForUser(userModule.state.userId, true);
      await refresh();
    };
    const openReportForm = async(lesson: Lesson): Promise<void> => {
      vm.loading = true;
      let reportsSinceLastPeriodicalReport = 0;
      for (let i = 0; i < vm.reports.length; i++) {
        if (vm.reports[i].reportType === "periodicalReport") break;
        reportsSinceLastPeriodicalReport++;
      }
      const firstLesson = await getFirstLesson(lesson.projectID);
      if (firstLesson.ID === lesson.ID) {
        vm.reportType = "firstReport";
      }
      else if (reportsSinceLastPeriodicalReport >= 5) {
        vm.reportType = "periodicalReport";
      }
      else {
        vm.reportType = "regularReport";
      }
      vm.studentName = lesson.project?.studentName ? lesson.project?.studentName : "eleven";
      vm.lesson = lesson;
      vm.reportFormVisible = true;
      vm.loading = false;
    };
    return {
      isLoading,
      missingReportlessons,
      name,
      openReportForm,
      readableDate,
      refreshAll,
      vm,
    };
  },
});
